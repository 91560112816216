export default Alpine.data('datepicker', (model, modelName, maxDate, minDate, clearable, hasTime, dataCy) => {
    return {
        model,
        modelName,
        picker: null,
        maxDate: maxDate ? Date.parse(maxDate) : Date.parse('2100-01-01 23:59'),
        minDate: minDate ? Date.parse(minDate) : Date.parse('1900-01-01 00:00'),
        hasTime: hasTime,
        convertDateToUserTimezone(date) {
            let dateObject = window.moment.tz(
                date,
                window.shelterTimezone
            )

            return dateObject
                .tz(Intl.DateTimeFormat().resolvedOptions().timeZone, true)
        },
        convertDateToUtc(date) {
            let dateObject = window.moment.tz(
                date,
                Intl.DateTimeFormat().resolvedOptions().timeZone
            )

            return dateObject
                .tz(window.shelterTimezone, true)
                .utc()
        },
        handleUpdate: (selectedDates, alpineObject) => {
            let date = alpineObject.convertDateToUtc(selectedDates[0])

            if (!selectedDates[0] && clearable) {
                alpineObject.model = ''
            } else if (date.isValid()) {
                alpineObject.model = date.format('YYYY-MM-DD HH:mm')
            }
        },
        changeMinDate(event) {
            if (event.detail.model === this.modelName) {
                this.picker.set('minDate', this.convertDateToUserTimezone(Date.parse(event.detail.value || '1900-01-01 00:00')).toDate())
            }
        },
        changeMaxDate(event) {
            if (event.detail.model === this.modelName) {
                this.picker.set('maxDate', this.convertDateToUserTimezone(Date.parse(event.detail.value || '2100-01-01 23:59')).toDate())
            }
        },
        async init() {
            this.$watch('model', value => {
                this.syncDate(value)
            })

            let config = {
                dateFormat: 'Z',
                defaultDate: this.model ? this.convertDateToUserTimezone(window.moment.utc(this.model)).toISOString() : null,
                defaultHour: 0,
                allowInput: true,
                maxDate: this.convertDateToUserTimezone(new Date(this.maxDate)).toDate(),
                minDate: this.convertDateToUserTimezone(new Date(this.minDate)).toDate(),
                disableMobile: true,
                altInput: true,
                altFormat: 'm/d/Y',
                onChange: (selectedDates) => {
                    this.handleUpdate(selectedDates, this)
                },
                onClose: (selectedDates) => {
                    this.handleUpdate(selectedDates, this)
                },
            }

            if (this.hasTime) {
                config.enableTime = true
                config.altFormat = 'm/d/Y h:i K'
            }

            this.picker = flatpickr(this.$refs.input, config)

            await this.$nextTick()

            const inputMaskFormat = this.hasTime ? "mm/dd/yyyy hh:MM TT" : "mm/dd/yyyy"
            const placeholder = this.hasTime ? 'MM/DD/YYYY HH:MM am' : 'MM/DD/YYYY'
            const min = this.hasTime ? "01/01/1900 12:00 am" : '01/01/1900';
            const max = this.hasTime ? "12/31/2100 12:59 pm" : '12/31/2100';

            const inputElement = window.document.querySelector(`#${this.$refs.input.id}` + " ~ input")
            if (inputElement) {
                inputElement.setAttribute("data-cy", dataCy)
                inputElement.setAttribute("placeholder", placeholder)

                window.Inputmask('datetime', {
                    inputFormat: inputMaskFormat,
                    inputMode: 'numeric',
                    max,
                    min
                }).mask(inputElement);
            }

        },
        syncDate(value) {
            if (!value) {
                this.picker.clear();

                return;
            }
            this.picker.setDate(window.moment.utc(value).tz(window.shelterTimezone).format('YYYY-MM-DD HH:mm:ss'))
        }
    }
})
