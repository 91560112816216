import Sortable from 'sortablejs';
import _ from 'lodash';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import "@lottiefiles/lottie-player";
import './sentry';

window.Pusher = Pusher;

if (window.isAuthenticatedPage) {
    if (import.meta.env.VITE_PUSHER_APP_KEY) {
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: import.meta.env.VITE_PUSHER_APP_KEY,
            cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
            forceTLS: true
        });
    } else if (import.meta.env.VITE_REVERB_APP_KEY) {
        window.Echo = new Echo({
            broadcaster: 'reverb',
            key: import.meta.env.VITE_REVERB_APP_KEY,
            wsHost: import.meta.env.VITE_REVERB_HOST,
            wsPort: import.meta.env.VITE_REVERB_PORT,
            wssPort: import.meta.env.VITE_REVERB_PORT,
            forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
            enabledTransports: ['ws', 'wss'],
        });
    }
}

window._ = _;

import axios from 'axios';
window.axios = axios;
import moment from 'moment-timezone';
window.moment = moment;

window.makeSortable = function(element, args = {}) {
    return Sortable.create(element, args);
};


window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.withCredentials = true;
